import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import st from "./about.module.scss"

export default () => (
  <Layout>
    <SEO title="Page Not Found" />
    <header className={st.header}>
      <h1 className={st.sectionHeading}>Page Not Found</h1>
    </header>

    <div className={st.contentContainer}>
      <p
        style={{
          textAlign: "center",
          marginBottom: "30px",
        }}
      >
        Sorry, we could not find the page you requested. Please go back and try again.
      </p>
      <img
        src="./bokdong.jpeg"
        alt="a dog peeking"
        style={{
          maxWidth: '100%',
          display: 'block',
          margin: "50px auto",
        }}
      />
    </div>
  </Layout>
)
